import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from '@remix-run/react';
import {LoaderFunctionArgs, json} from '@remix-run/node';
import {useTranslation} from 'react-i18next';

import remixI18n from './i18n/i18next.server';
import {BrowserEnvironmentVariables} from './utilities/env';

export async function loader({request}: LoaderFunctionArgs) {
  const locale = await remixI18n.getLocale(request);

  const env: BrowserEnvironmentVariables = {
    // eslint-disable-next-line no-process-env
    NODE_ENV: process.env.NODE_ENV ?? 'development',
    // eslint-disable-next-line no-process-env
    BUGSNAG_API_KEY: process.env.BUGSNAG_API_KEY ?? 'UNKNOWN',
  };
  return json({
    locale,
    env,
  });
}

export default function App() {
  const {locale, env} = useLoaderData<typeof loader>();
  const {i18n} = useTranslation();

  return (
    <html lang={locale} dir={i18n.dir()}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Meta />
        <Links />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const shouldRevalidate = () => false;
